import React, { useState , useEffect } from 'react';
import {Link ,useHistory} from "react-router-dom"
// import FacebookIcon from '@material-ui/icons/Facebook';
// import InstagramIcon from '@material-ui/icons/Instagram';
// import TwitterIcon from '@material-ui/icons/Twitter';
// import YouTubeIcon from '@material-ui/icons/YouTube';
// import LinkedInIcon from '@material-ui/icons/LinkedIn';
// import SendIcon from '@material-ui/icons/Send';
// import LocationOnIcon from '@material-ui/icons/LocationOn';
// import PhoneForwardedIcon from '@material-ui/icons/PhoneForwarded';
// import { Flare } from '@material-ui/icons';
// import KeyboardArrowRightSharpIcon from '@material-ui/icons/KeyboardArrowRightSharp';
import logo1 from '../assets/images/logo-kart2.png';
import payment from '../asset1/images/payments.png';

// import FacebookIcon from '@mui/icons-material/Facebook';
  const Footer =(props)=> {
    const [MainCatogories , setMainCategories] = useState([])
    useEffect(() =>{

    fetch("https://chand-rentacar-main-back.vercel.app/MainCatogories",{
            method: "GET",
             headers :  {
             "Content-Type" : "application/json" , 
         } ,
        })
        .then(res3=>res3.json())
        .then(res4=>{
            let dat = []
            let dat1 = []
            res4.map((res55,c)=>{
              if(c < 10){
                dat.push(res55)
                dat1.push(0)

              }
            })
                        console.log(dat)
            let data = dat.sort((a, b) => {
                return a.sort - b.sort;
            });
            setMainCategories(data)

        })
      },[])

  //  history = useHistory();
  
        return (
            <>
          <footer className="footer footer-2 font-weight-normal" >
              
              {/* End .cta */}
              <div className="container">
                <hr className="mt-0 mb-0" style={{borderColor: '#259FDD'}} />
              </div>
              <div className="footer-middle border-0">
                <div className="container">
                  <div className="row">
                    <div className="col-12 col-lg-2-5cols">
                      <div className="widget widget-about mb-4">
                        <img src={logo1} className="footer-logo" alt="Footer Logo" width={105} height={25} style={{height: "88px",
    width: "204px"}}/>
                        <p className="font-weight-normal" style={{color : "white"}}>Welcome to Abbottabad Luxury Rides Tour and Rent a Car, where sophistication meets convenience. Explore our distinguished fleet featuring premier brands such as Honda (Civic, City, BRV, etc.), Toyota (Vigo, Revo, Prado, Fortuner, V8, Corolla, Altis, Yaris, XLI, GLI, etc.), Suzuki (Cultus, Alto, etc.), Mercedes (S-Class, etc.), BMW, and more.


                         </p>
                        <div className="widget-about-info">
                          <div className="row">
                            <div className="col-sm-6 col-md-6">
                              <span className="widget-about-title" style={{fontWeight : 600,fontSize : "16px" , color : "white"}}>Any Question?</span>
                              <a href="tel:+923152200096" style={{fontWeight : 100,color : "white"}}>+92 315 2200096</a> <br />
                            </div>
                        
                            <div className="col-sm-6 col-md-6">
                              <span className="widget-about-title" style={{fontWeight : 600,fontSize : "16px" , color : "white"}}>Any Query</span>
                              <a href="mailto:info@abbottabadluxuryrentacar.com" style={{fontWeight : 100,color : "white"}}>info@abbottabadluxuryrentacar.com</a> <br />

                            </div>
                      
                           
                            
                            {/* End .col-sm-6 */}
                          </div>
                          {/* End .row */}
                        </div>
                        {/* End .widget-about-info */}
                      </div>
                      {/* End .widget about-widget */}
                    </div>
                    {/* End .col-sm-12 col-lg-3 */}
                    <div className="col-sm-3 col-lg-5cols">
                      <div className="widget mb-4">
                        <h4 className="widget-title" style={{fontWeight : 600,fontSize : "16px" , color : "white"}}>Information</h4>
                        {/* End .widget-title */}
                        <ul className="widget-list" style={{color : "white"}}>
                          <li>
                            <Link to="/about">About Us</Link>
                          </li>
                          {/* <li>
                            <Link to="/tourism">Tourism</Link>
                          </li> */}
                           {/* <li>
                            <Link to="/">Returns</Link>
                          </li> */}
                          {/* <li>
                            <Link to="/Shipping">Shipping</Link>
                          </li> */}
                          <li>
                            <Link to="/term-policy">Terms and conditions</Link>
                          </li>
                          <li>
                            <Link to="/privacy">Privacy Policy</Link>
                          </li>
                          {/* <li>
                            <Link to="/">How to shop on Kart Budget</Link>
                          </li> */}
                          {/* <li>
                            <Link to="/help">FAQ</Link>
                          </li> */}
                          <li>
                            <Link to="/contact">Contact us</Link>
                          </li>
                          {/* <li>
                            <Link to="/login">Log in</Link>
                          </li> */}
                        </ul>
                        {/* End .widget-list */}
                      </div>
                      {/* End .widget */}
                    </div>
                    {/* End .col-sm-4 col-lg-3 */}
                  
                    {/* End .col-sm-4 col-lg-3 */}
                    <div className="col-sm-3 col-lg-5cols">
                      <div className="widget mb-4">
                        <h4 className="widget-title" style={{fontWeight : 600,fontSize : "16px" , color : "white"}}>Customer Service</h4>
                        {/* <h4 className="widget-title" style={{fontWeight : 600,fontSize : "16px" , color : "white"}}>My Account</h4> */}
                        {/* End .widget-title */}
                        <ul className="widget-list" style={{color : "white"}}>
                          {/* <li>
                            <Link to="/login">Sign In</Link>
                          </li> */}
                          <li>
                            <Link to="/Corporate-Equiries">Corporate Enquiries</Link>
                          </li>
                          <li>
                            <Link to="/cart">View Cart</Link>
                          </li>
                          <li>
                            <Link to="/whislist">My Wishlist</Link>
                          </li>
                          {/* <li>
                            <Link to="/track">Track My Order</Link>
                          </li> */}
                          <li>
                            <Link to="/FAQ">Help</Link>
                          </li>
                        </ul>
                        {/* End .widget-list */}
                      </div>
                      {/* End .widget */}
                    </div>
                    {/* End .col-sm-64 col-lg-3 */}

  <div className="col-sm-3 col-lg-5cols">
                      <div className="widget mb-4">
                        <h4 className="widget-title" style={{fontWeight : 600,fontSize : "16px" , color : "white"}}>Category</h4>
                        <ul className="widget-list" style={{color : "white"}}>
                        {MainCatogories.map((res,i)=>{
                    return (
                      <>  
                       <li>
                            <a href={"/categories/"+res.MainCategories +"/all-products"}>{res.MainCategories}</a>
                          </li>
                           </>
                    
                    )
                  
                  })
                

                              }
                        </ul>
                      </div>
                    </div>


                  </div>
                  {/* End .row */}
                </div>
                {/* End .container */}
              </div>
              {/* End .footer-middle */}
              <div className="footer-bottom font-weight-normal">
                <div className="container">
                  <p className="font-weight-normal ml-lg-2" style={{fontSize : "13px", fontWeight :"500",color : "white"}}>© Copyright 2023. All rights reserved by Abbottabad luxury rent a car . Powered by                        <b><a target='_blank' href="https://www.webocreators.com">Webo Creators</a></b>  


</p>
                  {/* End .footer-copyright */}
                  {/* <ul className="footer-menu justify-content-center">
                    <li>
                      <a href="#">Terms Of Use</a>
                    </li>
                    <li>
                      <a href="#">Privacy Policy</a>
                    </li>
                  </ul> */}
                  {/* End .footer-menu */}
                  <div className="social-icons social-icons-color justify-content-center">
                    <span className="social-label" style={{color : "white"}}>Social Media</span>
                    <a href="https://www.facebook.com/malikhammadowner" className="social-icon social-facebook" title="Facebook" target="_blank">
                      <i className="icon-facebook-f" />
                    </a>
                    {/* <a href="#" className="social-icon social-twitter" title="Twitter" target="_blank">
                      <i className="icon-twitter" />
                    </a> */}
                    {/* <a href="https://www.instagram.com/rentacarspd/" className="social-icon social-instagram" title="Instagram" target="_blank">
                      <i className="icon-instagram" />
                    </a> */}
                    {/* <a href="https://www.facebook.com/profile.php?id=100079612164972" className="social-icon social-youtube" title="Youtube" target="_blank">
                      <i className="icon-youtube" />
                    </a> */}
                  </div>
                  {/* End .soial-icons */}
                </div>
                {/* End .container */}
              </div>
              {/* End .footer-bottom */}
            </footer>
            
         
            </>
        )
    
}

export default Footer;