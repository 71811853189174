import React, { useState , useEffect } from 'react';
import { Link } from 'react-router-dom';
import swal from 'sweetalert';

const Contact = (props)=>  {


    useEffect(() => {
        window.scrollTo(0, 0)

    }, []);




        return (
            <div>
                <div className="bg-light py-3">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12 mb-0"><Link to="/">Home</Link> <span className="mx-2 mb-0">/</span> <strong className="text-black">Privacy Policy</strong></div>
                        </div>
                    </div>
                </div>
                <div className="container" style={{width : "99% " ,padding : "30px 30px"}}>
                        <div className="row">
                            <div className="col-xl-12 col-lg-12 col-md-12">
                                <div style={{fontWeight :"bold"}}>
                                {/* <h2>This Privacy Policy</h2> */}
                                <h2>Abbottabad luxury rent a car Privacy Policy</h2>

<p>When you choose to avail the services of Abbottabad luxury rent a car, you entrust us with your personal data, and we are committed to maintaining that trust. This document aims to provide clarity on our privacy practices to ensure your understanding.</p>

<p>This notice outlines the personal data we collect, its use, sharing procedures, and the choices you have regarding this information.</p>

<p>This notice is applicable to users of Abbottabad luxury rent a car services worldwide, including users of our apps, websites, features, or other services.</p>

<h2>How We Use Personal Data Provided by You or Collected From You:</h2>

<ul>
    <li>Provide, maintain, and enhance our services, including facilitating payments, sending receipts, offering products and services you request, developing new features, providing customer support, developing safety features, authenticating users, and sending product updates and administrative messages.</li>
    <li>Conduct internal administration and operations, such as preventing fraud and abuse, troubleshooting software bugs, conducting data analysis, testing, and research, and monitoring and analyzing usage and activity trends.</li>
</ul>

<h2>Security Precautions</h2>

<p>Your profile is password-protected to ensure that only you and authorized Abbottabad luxury rent a car employees have access to your account information. Our staff will never proactively reach out to you and ask for any personal account information, including your password.</p>

<p>Abbottabad luxury rent a car makes every effort to ensure that your personal data is secure on its system. We have dedicated staff who maintain our privacy policy and other privacy initiatives, periodically reviewing web security and ensuring that every Abbottabad luxury rent a car employee is aware of our security practices. While we take extensive measures, no data transmission over the internet can be guaranteed to be 100% secure. As a result, Abbottabad luxury rent a car cannot guarantee the security of any personal data you transmit to us, and you do so at your own risk. For further questions, refer to our customer terms of use. To the extent permitted by applicable law, Abbottabad luxury rent a car expressly disclaims any liability that may arise should any other individuals obtain the personal data you submit to Abbottabad luxury rent a car.</p>

<h2>Changes To This Privacy Policy</h2>

<p>We may modify this privacy policy periodically. If there are significant changes in the way we treat your personal data or to the privacy policy, we will notify you through the services or by other means, such as email. Your continued use of the services after such notice constitutes your consent to the changes. We encourage you to review this privacy policy periodically for the latest information on our privacy practices.</p>

                                </div>
                            </div>
                        </div>
                </div>
            </div>
        )
    
}


export default Contact;