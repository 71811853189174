import React, { useState , useEffect } from 'react';
import { Link } from 'react-router-dom';
import swal from 'sweetalert';
import ch6 from '../asset1/images/contact-header-bg.jpg';
import ch1 from '../assets/images/Profile1.jpg';
import ch2 from '../assets/images/Profile2.jpg';






const Contact = (props)=>  {
    const [name, setname] = useState("") 
    const [lname, setlname] = useState("") 
    const [email, setemail] = useState("") 
    const [subject, setsubject] = useState("") 
    const [Message, setMessage] = useState("") 
    const [phone, setphone] = useState("") 
    const [drop, setdrop] = useState("") 
    const [car, setcar] = useState("") 
    const [day1, setday1] = useState("")
    const [Product_Image_Upload , setProduct_Image_Upload] = useState("")
 
    useEffect(() =>{
        window.scrollTo(0, 0)

    },[])

    const Submitdata = (e)=>{
        e.preventDefault()

        const data = new FormData()
        data.append("file", Product_Image_Upload)
        data.append("upload_preset", "commerce")
        data.append("cloud_name", "freedeveloperss123")
        fetch("https://api.cloudinary.com/v1_1/freedeveloperss123/image/upload",{ 
            method : "POST",
            body : data , 
        })
        .then(res=>res.json())
        .then((res2)=>{
          console.log(res2)
          // this.setState({ url : res2.url})
          // setUrl(res2.url)
          if(res2.url !== ""){
                              console.log(res2)
        fetch("https://chand-rentacar-main-back.vercel.app/Corporate-Enquiries",{
            method: 'POST' , 
            headers :  {
              "Content-Type" : "application/json" , 
            } , 
            body : JSON.stringify({
              name  ,
              lname  ,
              email  ,
              phone  ,
              car  ,
              Enquiry : drop,
              day1  ,
              setProduct_Image_Upload : res2.url  ,
              Message  ,
            })
          })
          .then((res)=>res.json())
          .then((res2)  =>{
              console.log(res2)
              if (res2){
                swal("Data Send ! Wait For Our Team Contact You "  )
                setname ("") 
                setlname("") 
                    setemail("") 
                    setsubject("") 
                    setMessage("") 
                // localStorage.setItem("User" , JSON.stringify("Yes") )
                    props.history.push("/")
              }
              else if (res2.Error){
                swal(res2.Error )
              }
              else{
                swal("Plz Try Again !"  )
              }
            // console.log(res2)
          })
          .catch((err)=>console.log(err))
        }
        else{
            swal("Upload Again")                  
        }
        })
        
    }



        return (
            <> 

            <div>
                <main className="main">
        <nav aria-label="breadcrumb" className="breadcrumb-nav border-0 mb-0">
          <div className="container">
            <ol className="breadcrumb">
              <li className="breadcrumb-item"><a href="">Home</a></li>
              {/* <li className="breadcrumb-item"><a href="#">Pages</a></li> */}
              <li className="breadcrumb-item active" aria-current="page">Corporate Enquiries
</li>
            </ol>
          </div>{/* End .container */}
        </nav>{/* End .breadcrumb-nav */}
        <div className="container">
          <div className="page-header page-header-big text-center" style={{backgroundImage:  'url('+ch6+')'}}>
            <h1 className="page-title text-white">Corporate Enquiries
<span className="text-white">Please fill out the form below and our representatives will be in touch with you shortly.

</span></h1>
          </div>{/* End .page-header */}
        </div>{/* End .container */}
















        <div className="page-content pb-0">
          <div className="container">
          <div className="row">
          <div className="row col-sm-5">
                 
                  <div className="col-sm-5">
                    <div className="contact-info">
                      <h3>The Office</h3>
                      <ul className="contact-list">
                        <li>
                          <i className="icon-clock-o" />
                          <span className="text-dark">Mon-Sun</span> <br />24/7 Hours
                        </li>
                        <li>
                          <i className="icon-clock-o" />
                          <span className="text-dark">Customer support timing </span> <br />24/7 Hours
                        </li>
                        
                      </ul>{/* End .contact-list */}
                    </div>{/* End .contact-info */}
                  </div>{/* End .col-sm-5 */}
                </div>{/* End .row */}
              <div className="col-lg-7">
                <h2 className="title mb-1">Drop Your Query
</h2>{/* End .title mb-2 */}
                <p className="mb-2">Use the form below to get in touch with the sales team</p>
                <form  onSubmit={(e)=>Submitdata(e)}>
                  <div className="row"> 
                    <div className="col-sm-6">
                      <label htmlFor="cname" >First Name</label>
                      <input type="text" required className="form-control" id="cname" placeholder="Name *"  value={name} onChange={(e)=>setname(e.target.value)}/>
                    </div>{/* End .col-sm-6 */}
                    <div className="col-sm-6">
                      <label htmlFor="cname" >Last Name</label>
                      <input type="text" required className="form-control" id="cname" placeholder="Name *"  value={lname} onChange={(e)=>setlname(e.target.value)}/>
                    </div>{/* End .col-sm-6 */}
                  </div>{/* End .row */}
                  <div className="row">
                    <div className="col-sm-6">
                      <label htmlFor="cphone" >Phone</label>
                      <input type="tel"  required className="form-control" id="cphone" placeholder="Phone"  value={phone} onChange={(e)=>setphone(e.target.value)}/>
                    </div>{/* End .col-sm-6 */}
                    <div className="col-sm-6">
                      <label htmlFor="cemail" >Email</label>
                      <input type="email" required className="form-control" id="cemail" placeholder="Email *" value={email} onChange={(e)=>setemail(e.target.value)}  />
                    </div>{/* End .col-sm-6 */}
                </div>{/* End .col-sm-6 */}
                  <div className="row">
                    <div className="col-sm-6">
                      <label htmlFor="cphone" >No of Cars *:</label>
                      <input type="number" required className="form-control" id="cphone" placeholder="No of Cars"  value={car} onChange={(e)=>setcar(e.target.value)}/>
                    </div>{/* End .col-sm-6 */}
                    <div className="col-sm-6">
                      <label htmlFor="cemail" >No of Days *:
</label>
                      <input type="number" className="form-control" id="cemail" placeholder="No of Days *: *" value={day1} onChange={(e)=>setday1(e.target.value)}  />
                    </div>{/* End .col-sm-6 */}
                </div>{/* End .col-sm-6 */}
                  <div className="row">
                    <div className="col-sm-6">
                      <label htmlFor="cphone" >Upload Cnic</label>
                      <input type="file"  required className="form-control" id="cphone" placeholder="No of Cars"   onChange={(e)=>setProduct_Image_Upload(e.target.files[0])} />
                    </div>{/* End .col-sm-6 */}
                    <div className="col-sm-6">
                      <label htmlFor="cemail" >Purpose of Enquir requiredy *:

</label>
                    <select class="form-control"  required="required" aria-required="true"  value={drop} onChange={(e)=>setdrop(e.target.value)} >
                      <option value="Corporate Sale">Corporate Sale</option>
                      <option value="General Enquiries">General Enquiries</option>
                    </select>

                    </div>{/* End .col-sm-6 */}
                </div>{/* End .col-sm-6 */}
                <div className="col-sm-6">

                  <label htmlFor="cmessage" >Message</label>
                  <textarea required className="form-control" cols={30} rows={4} id="cmessage"  placeholder="Message *" defaultValue={""}  value={Message}  onChange={(e)=>setMessage(e.target.value)} />
                  </div>
                  <button type="submit" className="btn btn-outline-primary-2 btn-minwidth-sm">
                    <span>SUBMIT</span>
                    <i className="icon-long-arrow-right" />
                  </button>
                </form>{/* End .contact-form */}
              </div>{/* End .col-lg-6 */}
            </div>{/* End .row */}
            <hr className="mt-4 mb-5" />
            
          </div>{/* End .container */}
          {/* <div id="map" /> */}
          {/* End #map */}
        </div>{/* End .page-content */}
      </main>{/* End .main */}





              
            </div>
            </>
           
        )
    
}


export default Contact;