import React from 'react';
import { BrowserRouter as Router, Link, Route, Switch,useHistory } from 'react-router-dom';
import Home from './Pages/Home';
import About from './Pages/About';
// import Shop from './Pages/Shop';
import Contact from './Pages/Contact';
// import Detail from './Pages/Detail';
import Card from './Pages/Card';
import Checkout from './Pages/Checkout';
import Checkout2 from './Pages/checkout2';
import Categories from './Pages/Categories';
import Categories1 from './Pages/Categories1';
// import Thankyou from './Pages/Thankyou';
import Header from './Components/Header';
import Footer from './Components/Footer';

// import demo from './Pages/demo';
// import CategoriesShop from './Pages/CategoriesShop';
import SearchData from './Pages/SearchData';

// import Signup from './Pages/Admin/Signup';
// import EditCard from "./Pages/EditCart"
// import CategoriesBook from './Pages/CategoriesBook';
// import CategoriesBags from './Pages/CategoriesBags';
// import CategoriesShoes from './Pages/CategoriesShoes';
// import ShoesProduct from './Pages/ShoesProduct';
// import ShoesProductDetails from './Pages/ShoesProductDetails';
// import SchoolClassProduct from './Pages/SchoolClassProduct';
// import BagsClassProduct from './Pages/BagsClassProduct';
// import chat from './Pages/chat';
// import SignupOtp from './Pages/SignupOtp';









import Privacy from './Pages/Privacy';
import consign from './Pages/consign';
// import Shipping from './Pages/Shipping';
import tracking from './Pages/tracking';
import FAQ from './Pages/FAQ';
import Shop from './Pages/Shop';
// import SaleSubCategoriesProduct from './Pages/SaleSubCategoriesProduct';
// import menSubCategoriesProduct from './Pages/menSubCategoriesProduct';
// import womenSubCategoriesProduct from './Pages/womenSubCategoriesProduct';
import SubCategoriesProduct from './Pages/SubCategoriesProduct';
import SubCategoriesProductDetails from './Pages/SubCategoriesProductDetails';
import SubCategoriesProductDetails1 from './Pages/SubCategoriesProductDetails1';
import SubCategoriesProductDetails2 from './Pages/SubCategoriesProductDetails2';
import SizeProductDetail from './Pages/SizeProductDetail';
import thankyou from './Pages/Thankyou';
import chat from './Pages/chat';
// import WomenOnlySubCategoriesProduct from './Pages/WomenOnlySubCategoriesProduct';
// import MenOnlySubCategoriesProduct from './Pages/MenOnlySubCategoriesProduct';
import SubCategoriesProduct1 from './Pages/SubCategoriesProduct1';
import SubCategoriesProduct2 from './Pages/SubCategoriesProduct2';
import Favorite from './Pages/Favorite';
import whislist from './Pages/whislist';
// import CategoriesUniform from './Pages/CategoriesUniform';
// import UniformClassProduct from './Pages/UniformClassProduct';
// import UniformProductDetails from './Pages/UniformProductDetails';
// import SchoolBookProductDetail from './Pages/SchoolBookProductDetail';
// import BagsProductDetail from './Pages/BagsProductDetail';
// import ArrivalCategoriesProduct from './Pages/ArrivalCategoriesProduct';

import whatapps from './assets/images/what.png';

// import teacherform from './Pages/teacherform';
// import studentform from './Pages/studentform';
import Shipping from './Pages/Shipping';
import Term from './Pages/Term';
import ab from './Pages/ab';
import expertpick from './Pages/expertpick';
import recommendedproduct from './Pages/recommendedproduct';
import newArrival from './Pages/newArrival';
import Error from './Pages/Error';
import CorporateEquiries from './Pages/CorporateEquiries';
import rentacarinAbbottabad from './Pages/rentacarinAbbottabad';
import rentacarinAbbottabad1 from './Pages/rentacarinAbbottabad1';
import rentacarinAbbottabad2 from './Pages/rentacarinAbbottabad2';
import rentacarinAbbottabad3 from './Pages/rentacarinAbbottabad3';
import rentacarinislamabad from './Pages/rentacarinislamabad';
import rentacarinpakistan from './Pages/rentacarinpakistan';
// import submain from './Pages/submain';

// import Privacy from './Pages/Privacy';
// import MainUserSignup from './Pages/MainUserSignup';
// import MainUserLogin from './Pages/MainUserLogin';
// import forgetpassaccount from './Pages/forgetpassaccount';


// import help from './Pages/help';
// import userDashboard from './Pages/userDashboard';
// import EditNameAddress from './Pages/EditNameAddress';
// import userDashOrderDetails from './Pages/userDashOrderDetails';


// import SchoolCategories from './Pages/SchoolCategories';
// import SchoolClassesCategories from './Pages/SchoolClassesCategories';
// import SchoolClassesCategoriesProduct from './Pages/SchoolClassesCategoriesProduct';
// import SchoolClassesCategoriesProductDetail from './Pages/SchoolClassesCategoriesProductDetail';


// import $ from 'jquery';








import rent2 from './Pages/rent2';
import rent3 from './Pages/rent3 (3)';
import rent4 from './Pages/rent3 (4)';
import rent5 from './Pages/rent3 (5)';
import rent6 from './Pages/rent3 (6)';
import rent7 from './Pages/rent3 (7)';
import rent8 from './Pages/rent3 (8)';
import rent9 from './Pages/rent3 (9)';
import rent10 from './Pages/rent3 (10)';
import rent11 from './Pages/rent3 (11)';
import rent12 from './Pages/rent3 (12)';
import rent13 from './Pages/rent3 (13)';
import rent14 from './Pages/rent3 (14)';
import rent15 from './Pages/rent3 (15)';
import rent16 from './Pages/rent3 (16)';
import rent17 from './Pages/rent3 (17)';
import rent18 from './Pages/rent3 (18)';
import rent19 from './Pages/rent3 (19)';
import rent20 from './Pages/rent3 (20)';
import rent21 from './Pages/rent3 (21)';
import rent22 from './Pages/rent3 (22)';
import rent23 from './Pages/rent3 (23)';
import rent24 from './Pages/rent3 (24)';
import rent25 from './Pages/rent3 (25)';
import rent26 from './Pages/rent3 (26)';
import rent27 from './Pages/rent3 (27)';
import rent28 from './Pages/rent3 (28)';
import rent29 from './Pages/rent3 (29)';
import rent30 from './Pages/rent3 (30)';
import rent31 from './Pages/rent3 (31)';
import rent32 from './Pages/rent3 (32)';
import rent33 from './Pages/rent3 (33)';
import rent34 from './Pages/rent3 (34)';
import rent35 from './Pages/rent3 (35)';
import rent36 from './Pages/rent3 (36)';
import rent37 from './Pages/rent3 (37)';
import rent38 from './Pages/rent3 (38)';
import rent39 from './Pages/rent3 (39)';


















// import "https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.10.0/css/all.min.css";
import "./bootstrap.min.css"
import "./all.css"
import "./custom.css"
import "./bootsnav.css"
import "./responsive.css"
import "./style1.css"
import "./css/style.css";
import "./lib/owlcarousel/assets/owl.carousel.min.css";

import "./css1/custom.css";
import "./css1/responsive.css";
import "./css1/bootstrap-select.css";
import "./css1/baguetteBox.min.css";
import "./css1/owl.carousel.min.css";
import "./css1/bootsnav.css";
import "./css1/code_animate.css";
import "./css1/carousel-ticker.css";
import "./css1/superslides.css";
import "./css1/all.css";
import "./css1/style.css";

import "./css1/bootsnav";

import "./asset1/vendor/line-awesome/line-awesome/line-awesome/css/line-awesome.min.css";
import "./asset1/css/bootstrap.min.css";
// import "./asset1/css/plugins/owl-carousel/owl.carousel.css";
import "./asset1/css/plugins/jquery.countdown.css";
import "./asset1/css/plugins/magnific-popup/magnific-popup.css";
import "./asset1/css/style.css";
import "./asset1/css/skins/skin-demo-26.css";
import "./asset1/css/demos/demo-26.css";



function App(props) {
  let history = useHistory();

  return (
    <div className="App" >                    
     <a href="https://wa.me/923152200096?text=Hi *Abbottabad luxury rent a car*! I need more info about Abbottabad luxury rent a car https://abbottabadluxuryrentacar.com" target="_blank"><img src={whatapps} alt="" style={{position : "fixed" ,zIndex : "999999", top : "80%" , right : "10px",width:"70px",height : "70px"}}/></a>
<div style={{position : "fixed" ,zIndex : "999999",display:"flex", bottom : "0%" , right : "0px",width:"100%",height : "54px",background:"white"}}>
  <div style={{width:"33.33%",textAlign:"center",color: "#259FDD",fontWeight:"bold",marginTop:"1px"}}> <a href="/contact"><i className="icon-envelope" /> <br />Book </a> </div>
  <div style={{width:"33.33%",textAlign:"center",color: "#259FDD",fontWeight:"bold",marginTop:"1px"}}> <a href="tel:+923152200096"><i className="icon-phone" /><br />Call</a> </div>
  <div style={{width:"33.33%",textAlign:"center",color: "#259FDD",fontWeight:"bold",marginTop:"1px"}}> <a href="https://www.google.com/maps/place/Abbottabad+luxury+rent+car/@34.201956,73.2376635,17.08z/data=!4m6!3m5!1s0x38de31e0862c305b:0x37c9fb9a927a10e8!8m2!3d34.203101!4d73.2390361!16s%2Fg%2F11smvh1325?entry=ttu"> <i className="icon-map-marker" /> <br />Direction</a> </div>
</div>
      <Router>

        <Header />
        
        <Switch>
          <Route exact path='/' component={Home} />
          <Route exact path='/term-policy' component={Term} />
          <Route exact path='/Shipping' component={Shipping} />
          <Route exact path='/Corporate-Equiries' component={CorporateEquiries} />
          {/* <Route exact path='/tourism' component={Tourism} /> */}
          <Route exact path='/product/new-arrival' component={newArrival} />
          <Route exact path='/product/recommended-product' component={recommendedproduct} />
          <Route exact path='/product/expert-pick' component={expertpick} />

          <Route exact path='/rent-a-car-in-abbottabad' component={rentacarinAbbottabad} />
          <Route exact path='/abbottabad-in-rent-a-car' component={rentacarinAbbottabad1} />
          <Route exact path='/abbottabad-rent-a-car' component={rentacarinAbbottabad2} />
          <Route exact path='/abbottabad-luxury-rent-a-car' component={rentacarinAbbottabad3} />
          <Route exact path='/rent-a-car-in-islamabad' component={rentacarinislamabad} />
          <Route exact path='/rent-a-car-in-pakistan' component={rentacarinpakistan} />
          <Route exact path='/shop-all' component={ab} />
          <Route exact path='/privacy' component={Privacy} />
          <Route exact path='/favorite' component={Favorite} />
          <Route exact path='/whislist' component={whislist} />
          <Route exact path='/thankyou' component={thankyou} />
          <Route exact path='/consign' component={consign} />
          <Route exact path='/checkout' component={Checkout} />
          <Route exact path='/checkout2' component={Checkout2} />
          <Route exact path='/FAQ' component={FAQ} />
          <Route exact path='/chat' component={chat} />
          <Route exact path='/cart' component={Card} />
          <Route exact path='/all-shop' component={Shop} />
          <Route exact path='/contact' component={Contact} />
          <Route exact path='/about' component={About} />
          <Route exact path='/track' component={tracking} />
          <Route exact path='/search/product-item' component={SearchData} />
          {/* <Route exact path='/ab' component={SubCategoriesProduct} /> */}
          {/* <Route exact path='/categories/:categoriesName/all-products' component={SubCategoriesProduct} /> */}
          <Route exact path='/main-categories/:categoriesName' component={Categories} />
          {/* <Route exact path='/main-categories/:categoriesName' component={Categories} /> */}
          <Route exact path='/main-categories-car-rental/:categoriesName' component={Categories1} />
          <Route exact path='/categories/:categoriesName/all-products' component={SubCategoriesProduct2} />
          <Route exact path='/categories/:categoriesName/:categoriesName1/all-products' component={SubCategoriesProduct1} />
          <Route exact path='/categories/:categoriesName/:categoriesName1/:categoriesName2/all-products' component={SubCategoriesProduct} />
          <Route exact path='/categories/:categoriesName/:categoriesName1/:categoriesName2/all-products/:productName/:productId' component={SubCategoriesProductDetails} />
          <Route exact path='/categories/:categoriesName/:categoriesName1/all-products/:productName/:productId' component={SubCategoriesProductDetails2} />
          <Route exact path='/categories/:categoriesName/all-products/:productName/:productId' component={SubCategoriesProductDetails1} />
          {/* <Route exact path='/main-categories/:categoriesName/all-products/:productName/:productId' component={submain} /> */}
          <Route exact path='/main-categories/:categoriesName/:categoriesName1/:categoriesName2/all-products/:productName/:productId' component={SizeProductDetail} />






          <Route exact path='/rent-a-car-in-abbottabad-available-cars-pricing' component={rent2} />
          <Route exact path='/rent-a-car-in-abbottabad-pakistan-with-driver-at-500-pkr' component={rent3} />
          <Route exact path='/car-rental-service-in-abbottabad' component={rent4} />
          <Route exact path='/rent-a-car-in-abbottabad-rates' component={rent5} />
          <Route exact path='/rent-a-car-in-abbottabad-with-driver' component={rent6} />
          <Route exact path='/rent-a-car-abbottabad' component={rent7} />
          <Route exact path='/cheap-car-hire-in-abbottabad' component={rent8} />
          <Route exact path='/abbottabad-rent-a-car-without-driver' component={rent9} />
          <Route exact path='/rent-a-car-price-in-abbottabad' component={rent10} />
          <Route exact path='/rent-a-car-in-abbottabad-contact-number' component={rent11} />
          <Route exact path='/rent-a-car-abbottabad-olx' component={rent12} />
          <Route exact path='/abbottabad-rent-a-car-monthly' component={rent13} />
          <Route exact path='/afridi-rent-a-car-abbottabad' component={rent14} />
          <Route exact path='/moon-city-tours-rent-a-car-abbottabad-abbottabad' component={rent15} />
          <Route exact path='/rent-a-car-islamabad' component={rent16} />
          <Route exact path='/cheap-rental-cars-abbottabad-book-now' component={rent17} />
          <Route exact path='/car-rental-abbottabad-total-price' component={rent18} />
          <Route exact path='/rent-a-car-in-abbottabad' component={rent19} />
          <Route exact path='/cheap-car-hire-in-abbottabad-pakistan' component={rent20} />
          <Route exact path='/rent-a-car-abbottabad-cheap-car-rentals-in-abbottabad' component={rent21} />
          <Route exact path='/midway-car-rental-car-rentals-in-abbottabad' component={rent22} />
          <Route exact path='/rent-a-car-abbottabad-viralreels-carrental-rentacar' component={rent23} />
          <Route exact path='/cheap-car-rentals-hire-car-in-abbottabad' component={rent24} />
          <Route exact path='/best-deals-on-cheap-abbottabad-car-rentals-2024' component={rent25} />
          <Route exact path='/cheap-rental-car-deals-in-abbottabad' component={rent26} />
          <Route exact path='/rent-your-ride-in-abbottabad-for-as-low-as-8-99-day' component={rent27} />
          <Route exact path='/search-convenient-car-rental-locations-in-abbottabad' component={rent28} />
          <Route exact path='/book-abbottabad-car-rentals' component={rent29} />
          <Route exact path='/rent-a-car-abbottabad-rental-services' component={rent30} />
          <Route exact path='/cheap-car-rental-in-abbottabad-carrentals-com' component={rent31} />
          <Route exact path='/rent-a-car-service-in-abbottabad' component={rent32} />
          <Route exact path='/premium-car-rentals-in-abbottabad' component={rent33} />
          <Route exact path='/rent-a-car-service-in-abbottabad' component={rent34} />
          <Route exact path='/abbottabad-car-rental-rates-compare-low-high-rates' component={rent35} />
          <Route exact path='/afridi-rent-a-car-abbottabad' component={rent36} />
          <Route exact path='/lagorent-rent-a-car-car-rental-in-abbottabad' component={rent37} />
          <Route exact path='/abbottabad-rent-a-car-pakistan-car' component={rent38} />
          <Route exact path='/rent-a-car-in-pakistan-car-rental-hire-online-booking' component={rent39} />


    


          <Route path='*' component={Error} />

        </Switch>

        <Footer />

      </Router>
    </div>
  );
}

export default App;









